export const ADD_VERIFICATIONS = "ADD_VERIFICATION";
export const DELETE_VERIFICATION = "DELETE_VERIFICATION";
export const USER_VERIFICATIONS = "USER_VERIFICATIONS";
export const SELECT_SCHOOL = "SELECT_SCHOOL";
export const ADD_TRANSCRIPT = "ADD_TRANSCRIPT";
export const GET_TRANSCRIPT = "GET_TRANSCRIPT";
export const GET_MESSAGES = "GET_MESSAGES";
export const DELETE_MESSAGES = "DELETE_MESSAGES";

export const GET_PAGE_DETAILS = "GET_PAGE_DETAILS";
export const LOADING = "LOADING";
export const NO_INSTITUTES = "NO_INSTITUTES";
